import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './store'
//import * as serviceWorker from './serviceWorker';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translation_en from './translations/en/common.json'
import translation_ru from './translations/ru/common.json'

const language = window.navigator ? (window.navigator.language ||
  window.navigator.systemLanguage ||
  window.navigator.userLanguage) : "ru";
const defaultLanguage = language.substr(0, 2).toLowerCase();

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: translation_en
      },
      ru: {
        translation: translation_ru
      },
    },
    lng: defaultLanguage !== "ru" ? "en" : defaultLanguage,
    fallbackLng: defaultLanguage !== "ru" ? "en" : defaultLanguage,

    interpolation: {
      escapeValue: false
    }
  });


const store = configureStore(/* provide initial state if any */);

console.log("index --> " + JSON.stringify(history));

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter location={history.location} listen={history.listen} history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("mamahod")
);
/*ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        Халва колбаса, холодильник "Бирюса"
      </div>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("mamahod")
);*/

/*ReactDOM.render(
  <Provider store={store}>
      <div>
        Халва колбаса, холодильник "Бирюса"
      </div>
  </Provider>,
  document.getElementById("mamahod")
);*/


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
