import React from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

/*import {
  HeaderMain,
  FormsArea,
} from '../../components/index'
import background from '../../assets/bg.jpg'
import styles from './styles.module.css'
import { clearWorkArea, setGoogleAPILoaded } from '../../modules/info/actions'
 */

/*export*/ const KotiSivuComponent = (props) => {

/*  return (
    <div className={styles["main-page"]}>
      <HeaderMain {...props} />
      <div className={styles["main-page-background"]}>
        <img src={background} alt="" />
      </div>
      {
        <FormsArea {...props} />
      }
      Здесь будет наш сайт
    </div>
  );*/

  return (
    <div>
      Здесь будет наш сайт
    </div>
  );
}; /* end of KotiSivuComponent */


const mapStateToProps = (/*{ userInfo }*/) => ({
});

const mapDispatchToProps = dispatch => bindActionCreators({
//  clearWorkArea,
}, dispatch);

export const KotiSivu = connect(
//  mapStateToProps,
//  mapDispatchToProps,
)(KotiSivuComponent);
