import React from 'react';
import {
  Switch,
  //Routes,
  Route,
} from "react-router-dom";
import /** as Pages*/ { KotiSivu/*, Timetable, Routes, Blog, AboutUs*/ } from './pages/index';

function App() {
  return (
    <Switch>
      <Route exact path="/" render={(props) => </*Pages.*/KotiSivu {...props} />} />
      <Route exact path="/home" render={(props) => </*Pages.*/KotiSivu {...props} />} />
    </Switch>
  );
}

/*function App_0() {
  return (
    <Routes>
      <Route exact path="/" render={(props) => </*Pages.*//*KotiSivu {...props} />} />
      <Route exact path="/home" render={(props) => </*Pages.*//*KotiSivu {...props} />} />
{/*
      <Route exact path="/timetable" render={(props) => <Timetable {...props} />} />
      <Route exact path="/routes" render={(props) => <Routes {...props} />} />
      <Route exact path="/blog" render={(props) => <Blog {...props} />} />
      <Route exact path="/about" render={(props) => <AboutUs {...props} />} />
*//*}
    </Routes>
  );
}*/

export default App;
