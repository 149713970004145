import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
/*import userInfo from './info/reducer'
import serverData from './requests/reducer'*/


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
/*  userInfo,
  serverData*/
});
export default createRootReducer
